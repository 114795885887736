import Plyr from 'plyr';

const _element = '[data-plyr-provider]';
const $element = $( _element );

if ( $element.length ) {

    let $player = [];

    const _settings = {
        controls: false,
        muted: true,
        loop: {
            active: true
        }
    };

    $element.each( function( i ) {
        const $this = $(this);
        const _id = 'video-player-' + i;

        $this.attr('id', _id);

        $player[i] = new Plyr( '#' + _id, _settings );

        $this.parents( 'a' ).hover(function() {
            $player[i].play();          
        }, function() {
            $player[i].pause();
        });
    });
}