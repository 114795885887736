import setCookie from '../helpers/set-cookie';

$('.cookies a.btn').on('click', function(e) {
	e.preventDefault();

	let $this = $(this);

	if ($this.is('.btn--agree')) {
		setCookie( 'crb-cookies', 'yes', 30 );
	}

    if ($this.is('.btn--white')) {
        setCookie( 'crb-cookies', 'yes', 1 );
    }

	$('.cookies').remove();
});

if ( typeof crb_location !== 'undefined' ) {
	setCookie( 'crb-location-state', crb_location.id, 30 );
}
