import 'selectric';
import Swiper from 'swiper/js/swiper.min';
import 'magnific-popup';
import Typed from 'typed.js';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';
import './components/cookies';
import offersPopup from './components/offers-popup';
import './components/video-player';

const $win = $(window);
const $doc = $(document);
const $body = $('body');

// Detect Device ( includes Safari13 )
let isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
if ( !isMobile ) {
	$body.addClass('no-touch');
} else {
	$body.addClass('is-touch');
};

//  Nav mobile
$('.nav-trigger').on('click', function(e){
	e.preventDefault();

	$(this).toggleClass('open');
	$body.toggleClass('show-nav');
});

// Mobile Nav
 $('.nav a').on('click', function(e){
	const $this = $(this);
	let $parent = $this.parent();
	let $hasSubMenu = $this.next('ul');

	if($win.width() < 1024 && $hasSubMenu.length ) {
		if(!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
		}
	}

	if($body.hasClass('is-touch') && $hasSubMenu.length ) {
		if(!$parent.hasClass('hover')) {
			e.preventDefault();

			$parent.addClass('hover').siblings().removeClass('hover');
		}
	}
});

 $('.nav a').on('focus', function() {
    const $this = $(this);

    $this.next().show();
});

$win.on('scroll', function(){
	$body.toggleClass('scrolled', $win.scrollTop() > 0);
});

$body.on('click touchstart', function(event){
	let $target = $(event.target);

	if(!$target.parents('.nav li').length){
		$('.nav li').find('ul').parent().removeClass('hover');
	}

	if($body.hasClass('is-touch') && $win.width() <= 768){
		if(!$target.parents('.nav li').length ){
			$('.nav li').find('ul').removeClass('hover');
		}
	}
});

// Selectric
$('.form-select select, .form-search select').selectric({
	disableOnMobile: false,
	nativeOnMobile: false
});

$('.selectric-input').wrap('<label class="sr-only" aria-hidden="true">Hidden Label</label>');

// Swiper
const sliderBasic = new Swiper('.slider-basic .swiper-container', {
	slidesPerView: 'auto',
	spaceBetween: 40,
	resistanceRatio: 0,
	loop: true,
	centeredSlides: true,
	breakpoints: {
		1023: {
			slidesPerView: 5,
			spaceBetween: 20,
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 40,
		}
	}
});

const sliderLogos = new Swiper('.slider-logos .swiper-container', {
	slidesPerView: 'auto',
	resistanceRatio: 0,
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	breakpoints: {
   //  	560: {
			// slidesPerView: 4,
   //  	},
		1023: {
			slidesPerView: 6,
		}
	}
});

const sliderMain = new Swiper('.slider-main .swiper-container', {
	speed: 1000,
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// autoplay: {
	// 	delay: 4000,
	// 	disableOnInteraction: false
	// }
});

const sliderGallery = new Swiper('.slider-gallery .swiper-container', {
	speed: 1000,
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		type: 'fraction',
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});

const sliderTestimonials = new Swiper('.slider-testimonials .swiper-container', {
	speed: 1000,
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	autoplay: {
		delay: 4000,
		disableOnInteraction: false
	}
});

const sliderPrimary = new Swiper('.slider-primary .swiper-container', {
	speed: 1000,
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	// autoplay: {
	// 	delay: 4000,
	// 	disableOnInteraction: false
	// }
});

const sliderArticles = new Swiper('.slider-articles .swiper-container', {
	speed: 1000,
	effect: 'fade',
	loop: true,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	autoplay: {
		delay: 4000,
		disableOnInteraction: false
	},
});

sliderArticles.on('transitionStart', function(){
	if( $win.width() > 767 ){
		sliderArrowsposition();
	}
});

function sliderArrowsposition() {
	let $sliderHeight = $('.slider-articles').outerHeight();
	let $contentHeight = $('.slider-articles .swiper-slide-active .slide__content').outerHeight();
	let position = ($sliderHeight - $contentHeight) / 2 + 45;

	if( $win.width() < 1024 ){
		position = ($sliderHeight - $contentHeight) / 2 + 20;
	}

	$('.slider-articles .slider__actions').css('bottom', position);
}

$('.slider-logos .slide').on('click', 'a', function(e){
	e.preventDefault();

	let href = $(this).attr('href');
	let $headerHeight = $('.header').outerHeight();
	let target = $(href).offset().top - $headerHeight;

	if( $win.width() >= 1024 ){
		$headerHeight = 53;
	}

	if( $body.hasClass('admin-bar') ){
		const $adminHeight = $('#wpadminbar').outerHeight();

		target = $(href).offset().top - $headerHeight - $adminHeight;
	}

	$('body, html').animate({
		scrollTop: target

	}, 600);
});

$win.on('load', function(){

	// Popup Subscribe
	offersPopup();

	// Magnific Popup
	// Article Overlay
	$doc.on('click', 'a.ajax-popup-link', function(e){
		const $this = $(this);
		const $parent = $this.parent();

		e.preventDefault();

		if( $body.hasClass('is-touch') && !$('.section-popup').length && !$this.hasClass('active') ){
			$this.addClass('active');
			$parent.siblings().find('a').removeClass('active');
			return;
		}

		$this.removeClass('active');
		$.magnificPopup.open({
			items: {
				src: $this.attr('href')
			},
			type: 'ajax',
			callbacks: {
				parseAjax: function(mfpResponse) {
					mfpResponse.data = $(mfpResponse.data).find('.section-popup');
				},
				ajaxContentAdded: function() {
					let sliderGallery = new Swiper('.slider-gallery .swiper-container', {
						speed: 500,
						loop: true,
						pagination: {
							el: '.swiper-pagination',
							type: 'fraction',
						},
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
					});
				},
				close: function() {
					$('a.ajax-popup-link').removeClass('active');
				}
			}
		});
	});

	if( $body.hasClass('no-touch') ){
		initClearAjaxMagnificPopup()
	}

	if( $win.width() > 767 ){
		sliderArrowsposition();
	}
});

function initClearAjaxMagnificPopup(){
	$('.ajax-popup-link').magnificPopup({
		type: 'ajax',
		callbacks: {
			parseAjax: function(mfpResponse) {
				mfpResponse.data = $(mfpResponse.data).find('.section-popup');
			},
			ajaxContentAdded: function() {
				let sliderGallery = new Swiper('.slider-gallery .swiper-container', {
					speed: 500,
					loop: true,
					pagination: {
						el: '.swiper-pagination',
						type: 'fraction',
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				});

				initClearAjaxMagnificPopup();
			},
			close: function() {
				$('.article-box a.ajax-popup-link').removeClass('active');
			}
		}
	});
}

$('.js-popup-form').magnificPopup({
	type: 'inline',
	alignTop: true
});

// Packages article expand content
$('.article-form .article__expandable-head, .list-prices .list__content-head').on('click', function(){
	const $this = $(this);

	$this.next().slideToggle();
	$this.toggleClass('open');
});

// Type Effect
if( $('.typed').length ){
	const options = {
		stringsElement: '.typed-wrap',
		typeSpeed: 60,
		backSpeed: 60,
		loop: true,
		cursorChar: '',
		preStringTyped: function(pos, self){
			$('.section-careers .section__image-inner').removeClass('current').eq(pos).addClass('current');
		}
	};

	const typed = new Typed('.typed', options);
}

// Google Map
function initializeGoogleMaps() {
	$('.map .map__inner').each( (i, element) => {
		const markers = [];
		const infowindows = [];
		const $element = $(element);
		const center = new google.maps.LatLng( $element.data('lat'), $element.data('lng') );

		const googleMapStyle = [{"featureType": "administrative", "elementType": "all", "stylers": [{"saturation": "-100"} ] }, {"featureType": "administrative.province", "elementType": "all", "stylers": [{"visibility": "off"} ] }, {"featureType": "landscape", "elementType": "all", "stylers": [{"saturation": -100 }, {"lightness": 65 }, {"visibility": "on"} ] }, {"featureType": "poi", "elementType": "all", "stylers": [{"saturation": -100 }, {"lightness": "50"}, {"visibility": "simplified"} ] }, {"featureType": "road", "elementType": "all", "stylers": [{"saturation": "-100"} ] }, {"featureType": "road.highway", "elementType": "all", "stylers": [{"visibility": "simplified"} ] }, {"featureType": "road.arterial", "elementType": "all", "stylers": [{"lightness": "30"} ] }, {"featureType": "road.local", "elementType": "all", "stylers": [{"lightness": "40"} ] }, {"featureType": "transit", "elementType": "all", "stylers": [{"saturation": -100 }, {"visibility": "simplified"} ] }, {"featureType": "water", "elementType": "geometry", "stylers": [{"hue": "#ffff00"}, {"lightness": -25 }, {"saturation": -97 } ] }, {"featureType": "water", "elementType": "labels", "stylers": [{"lightness": -25 }, {"saturation": -100 } ] } ]

		const mapOptions = {
			zoom: 16,
			center,
			// disableDefaultUI: true,
			backgroundColor: '#ffffff',
			panControl: false,
			// zoomControl: false,
			scrollwheel: false,
			styles: googleMapStyle,
		};

		const icon = new google.maps.MarkerImage($element.data('marker'), null, null, null, new google.maps.Size(29, 41));

		const bounds = new google.maps.LatLngBounds();

		const map = new google.maps.Map(document.getElementById( $element.attr('id') ),mapOptions);

		google.maps.event.addListenerOnce(map, 'idle', () => {
			document.getElementsByTagName('iframe')[0].title = "Google Maps";
		});

		let position = new google.maps.LatLng( $element.data('lat'), $element.data('lng'));

		let marker;

		if ($('.intro-location-single').length) {
			marker = new google.maps.Marker({
				map,
				position,
				icon,
			});
		}

		if ($element.data('zoom')) {
			 map.setZoom($element.data('zoom'));
		}

		$('.section-map .article-location').each( (i, location) => {
			const $location = $(location);
			const lat = $location.data('lat');
			const lng = $location.data('lng');
			const info = $location.html();
			const id = $location.data('markerid');
			const $content = $location.find('.article__content');

			if (!lat && !lng) {
				return;
			}

			position = new google.maps.LatLng( parseFloat(lat), parseFloat(lng));

			bounds.extend(position);

			map.fitBounds(bounds);

			const infowindow = new google.maps.InfoWindow({
				content: info,
				maxWidth: 340
			});

			marker = new google.maps.Marker({
				map,
				position,
				icon,
				id,
			});

			infowindows[i] = infowindow;

			markers.push(marker);

			marker.addListener('mouseover', () => {
				for (let j = 0; j < infowindows.length; j++) {
					infowindows[j].close();
				}

				infowindows[i].open(map, markers[i]);
			});

			if( $body.hasClass('is-touch') ){
				marker.addListener('click', () => {
					for (let j = 0; j < infowindows.length; j++) {
						infowindows[j].close();
					}

					infowindows[i].open(map, markers[i]);
				});
			}

			$content.on('mouseover', () => {
				for (let j = 0; j < infowindows.length; j++) {
					infowindows[j].close();
				}

				infowindows[i].open(map, markers[i]);
				map.panTo(position);
			});
		});
	});
}

if ( $('.map').length > 0 ) {
	google.maps.event.addDomListener(window, 'load', initializeGoogleMaps);
}

// Malihu Custom Scrollbar
if ( $win.width() > 1023 ) {
	$('.section-map .articles-location').mCustomScrollbar({
		theme: 'dark',
	});
}

// Locations Search Form
if( $('.form-search').length ){
	const $input = $('.form-search').find('.form__fields input');

	$('.form-search select').on('change', function(){
		$input.attr('placeholder', $(this).find(':selected').data('placeholder'));
	});
}

// Tabs

const activeTabClass = 'current';

$('.tabs .tabs__nav a, .tabs .tab__head a').on('click', function(event) {
	event.preventDefault();

	let $tabLink = $(this);
	var $targetTab = $($tabLink.attr('href'));

	$tabLink = $tabLink.parent();

	$tabLink
		.add($targetTab)
		.addClass(activeTabClass)
			.siblings()
			.removeClass(activeTabClass);

	$doc.trigger('tab-change', $targetTab);
});

if ($('.section--select form').length) {
	$('.section--select form select').on('change', function(){
		$('.section--select form').submit();
	})
}

const userAgent = window.navigator.userAgent;

/**
 * Check whether the browser is Internet Explorer.
 *
 * @return {boolean}
 */
export const isIe = () => {
	return userAgent.indexOf('Trident/') > 0;
};

/**
 * Check whether the browser is Edge.
 *
 * @return {boolean}
 */
export const isEdge = () => {
	return userAgent.indexOf('Edge/') > 0;
};

if (isIe()) {
	$('.js-image-fit').each((i, container) => {
		const $container = $(container);
		const $image = $container.find('img');
		const imageSource = $image.attr('src') || $image.data('src');

		$container.css('background-image', `url(${imageSource})`);
		$image.addClass('sr-only');
	});
}
