import setCookie from '../helpers/set-cookie';

export default function offersPopup() {
	const $body = $('body');
	const $doc = $(document);

	if ($('.popup-subscribe').length) {
		setTimeout(function() {
			$body.addClass('open-popup');
		}, crb_site_options.popup_seconds * 1000);

		$('.popup-subscribe .btn-close').on('click', (e) => {
			e.preventDefault();

			$body.removeClass('open-popup');
			setCookie( 'crb-offers', 'yes', 1 );
		});

		$('.popup-subscribe .btn-disagree').on('click', (e) => {
			e.preventDefault();
			
			$body.removeClass('open-popup');
			setCookie( 'crb-offers', 'yes', 30 );
		});

		$doc.on('gform_confirmation_loaded', (event, formId) => {
			if(formId == crb_site_options.popup_form) {
				setCookie( 'crb-offers', 'yes', 30 );

				$('.popup-subscribe .popup__content p').hide();
				$('.popup-subscribe .btn-disagree').hide();

				setTimeout(function() {
					$body.removeClass('open-popup');
				}, 3000);
			}
		});
	}
}